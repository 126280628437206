<template>
  <Content :search="false" :filter="true" :showFilterDefault="true">
    <template v-slot:beforeSearch>
      <div class="flex items-center gap-2 text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <template v-if="customer">
          <h2>{{ customer.full_name }} pasūtītās preces</h2>
        </template>
      </div>
    </template>

    <template v-slot:filter>

      <div class="w-48 mr-3">
        <Radio radioName="filter" v-model="selectedFilter" :items="filters" @change="filterOrders"/>
      </div>
    </template>
    
    <template v-slot:content>
      <div>
        <template v-if="allCustomerOrders && allCustomerOrders.length">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="order in allCustomerOrders" :key="order.id">
                <SingleCustomerOrder :order="order" />
              </template>
            </ul>
          </div>
        </template>
        <template v-else>
          <p class="py-10 px-5 text-xl text-center dark:text-gray-400">Klientam nav neviena pasūtījuma</p>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import Radio from "@/components/Components/Radio"
import { defineAsyncComponent } from 'vue'

const SingleCustomerOrder = defineAsyncComponent(() =>
  import('@/components/Customers/Orders/SingleCustomerOrder'))

export default {
  name: "CustomerOrders",
  components: {
    SingleCustomerOrder,
    Radio,
  },
  data() {
    return {
      selectedFilter: 0,
      filters: [
        {
            name: 'Visi',
            value: 0
        },
        {
            name: 'Kasetes',
            value: 1
        },
      ],
      allCustomerOrders: null
    }
  },
  mounted() {
    this.$store.dispatch('getCustomer', this.$route.params.customerId)
    this.$store.dispatch('getCustomerOrders', this.$route.params.customerId).then(response => {
      this.allCustomerOrders = this.customerOrders
    })
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
      customerOrders: 'customerOrders',
    })
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    filterOrders() {
      if (this.selectedFilter === 1) {
        this.allCustomerOrders = this.customerOrders.filter(order => order.is_for_production)
      } else {
        this.allCustomerOrders = this.customerOrders
      }
    }
  }
}
</script>

<style>

</style>